import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteUser } from "../../services/users";
import CustomTable from "../CustomTable";
import moment from "moment";

const TableUsers = ({ data, canEdit, canDelete, refresh, tableReference, }) => {
  const MySwal = withReactContent(Swal);
  const history = useNavigate();

  const columns = [
    {
      title: "Id",
      field: "_id",
      hidden: true,
    },
    {
      title: "First Name",
      field: "firstName",
      //sorting: false,
    },
    {
      title: "Last Name",
      field: "lastName",
      //sorting: false
    },
    {
      title: "Phone",
      field: "phone",
      //sorting: false
    },
    {
      title: "Email",
      field: "email",
      //sorting: false
    },
    {
      title: "Last login",
      field: "lastLogin",
      render: (rowData) => {
        const lastLogin = rowData.lastLogin === ""
            ? "-"
            :moment(rowData.lastLogin, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        return(
            <span>{lastLogin}</span>
        )
      }
    },
    {
      title: "ClientId",
      field: "clientId",
      hidden: true,
    },
  ];

  const options = {
    sorting: false,
    search: false
  };

  let actions = [];
  if (canEdit) {
    actions.push((rowData) => ({
      icon: () => <EditIcon color="primary" />,
      tooltip: "Edit User",
      onClick: () => {
        goToRecord(rowData._id, rowData.clientId);
      },
    }));
  }

  if (canDelete) {
    actions.push((rowData) => ({
      icon: () => <DeleteIcon className="text-danger" />,
      tooltip: "Delete User",
      onClick: () => delUser(rowData._id),
    }));
  }

  const goToRecord = (id, clientId) => {
    history("/user-form", { state: { id, clientId } });
  };

  const delUser = (id) => {
    MySwal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(id).then(([status]) => {
          if (status) {
            refresh();
          }
        });
      }
    });
  };

  return (
    <div>
      <CustomTable
        pageSize={10}
        options={options}
        columns={columns}
        asyncDataSource={data}
        actions={actions}
        tableReference={tableReference}
      />
    </div>
  );
};
export default TableUsers;
